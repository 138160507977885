<!--suppress ALL -->
<template>
  <div id="app">
    <Loading v-if="$store.state.loading" />
    <Simplert :useRadius="true" :useIcon="true" ref="notif" />
    <router-view />
  </div>
</template>

<script>
import Simplert from "vue2-simplert/src/Simplert";
import Loading from "./components/Loading";

export default {
  components: {
    Simplert,
    Loading,
  },
  data() {
    return {};
  },
  mounted() {
    this.$root.$on("setNotif", (data) => {
      this.$refs.notif.openSimplert(data);
    });
    if (this.$store.state.token) {
      this.$psb.nes.instance.connect();
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/main.scss";
</style>

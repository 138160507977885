// noinspection ES6ConvertVarToLetConst

import monthSchema from "./month.json";

const dateFormat = (d, def = null) => {
  var isoDate = (withtime = false) => {
    if (d) {
      var m = new Date(d);
      // var dateString =
      // m.getUTCFullYear() + "-" +
      // ("0" + (m.getUTCMonth()+1)).slice(-2) + "-" +
      // ("0" + m.getUTCDate()).slice(-2);
      // if(withTime) {
      //   var timeString =
      //   ("0" + m.getUTCHours()).slice(-2) + ":" +
      //   ("0" + m.getUTCMinutes()).slice(-2) + ":" +
      //   ("0" + m.getUTCSeconds()).slice(-2);
      //   return dateString + " " + timeString;
      var dateString =
        m.getFullYear() +
        "-" +
        ("0" + (m.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + m.getDate()).slice(-2);
      if (withtime) {
        var timeString =
          ("0" + m.getHours()).slice(-2) +
          ":" +
          ("0" + m.getMinutes()).slice(-2) +
          ":" +
          ("0" + m.getSeconds()).slice(-2);
        return dateString + " " + timeString;
      } else {
        return dateString;
      }
    } else {
      return def;
    }
  };

  var toHumanDate = (withtime = false) => {
    if (!d || d == null || d == "0000-00-00 00:00:00" || d == "0000-00-00") {
      // di isoDate default = null, tetapi humanDate default = '-'
      if (def == null) def = "-";
      return def;
    } else {
      if (typeof d == "string") d = d.replace(" ", "T");
      var date = new Date(d);
      var dates = {
        date: date.getDate(),
        month: monthSchema[date.getMonth()],
        year: date.getFullYear(),
      };
      if (withtime) {
        dates = Object.assign(dates, {
          sec: date.getSeconds(),
          minute: date.getMinutes(),
          hour: date.getHours(),
        });
        return `${dates.date} ${dates.month} ${dates.year} ${dates.hour}:${dates.minute}:${dates.sec}`;
      } else {
        return `${dates.date} ${dates.month} ${dates.year}`;
      }
    }
  };

  var timeframe = (timeframe) => {
    switch (timeframe) {
      case "weekly":
        var week = d.split("-");
        if (d && d != "0000-00" && week.length == 2) {
          return `${week[0]} minggu ke ${week[1]}`;
        }
        break;
      case "monthly":
        var monthly = d.split("-");
        if (d && d != "0000-00" && monthly.length == 2) {
          return `${monthly[0]} ${monthSchema[monthly[1] - 1]}`;
        }
        break;
      case "daily":
      default:
        return toHumanDate();
        break;
    }
  };

  return {
    timeframe,
    toIsoDate: () => {
      return isoDate();
    },
    toIsoDateTime: () => {
      return isoDate(true);
    },
    toDate: () => {
      return toHumanDate();
    },
    toDateTime: () => {
      return toHumanDate(true);
    },
  };
};

const getYear = (d) => {
  return new Date(d).getFullYear();
};

export { dateFormat, getYear };

<template>
  <div>
    <nav
      class="navbar navbar-landing shadow navbar-expand-lg navbar-dark bg-success"
    >
      <div class="container d-flex bd-highlight">
        <button
          @click="$router.push('/login')"
          class="btn btn-sm btn-outline-light my-2 my-sm-0"
        >
          Kembali
        </button>
        <div class="navbar-brand flex-fill text-center">
          <span>Pemulihan Password</span>
        </div>
      </div>
    </nav>

    <main class="forgot-password-wrapper p-4 shadow-sm">
      <form @submit.prevent="request" v-if="mode === ''">
        <div class="form-group pt-3">
          <label for="nomor-register">Nomor Registrasi</label>
          <input
            :disabled="loading"
            v-model="no_registrasi"
            name="No. Registrasi"
            v-validate="'required|numeric'"
            :class="{ 'is-invalid': errors.has('No. Registrasi') }"
            type="text"
            class="form-control"
            id="nomor-register"
            placeholder="Masukan Nomor Registrasi"
          />
          <div
            v-show="errors.has('No. Registrasi')"
            class="invalid-feedback"
            v-text="errors.first('No. Registrasi')"
          ></div>
          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
        </div>

        <hr />

        <div class="form-group pt-3 text-center">
          <button
            :disabled="loading"
            type="submit"
            class="btn btn-primary px-4"
          >
            Pulihkan
          </button>
        </div>
      </form>

      <form @submit.prevent="konfirmasi" v-if="mode === 'konfirmasi'">
        <h2 class="text-center text-muted" v-text="timeout"></h2>
        <div class="form-group pt-3">
          <label for="kode-konfirmasi">Kode Konfirmasi</label>
          <input
            :disabled="loading"
            v-model="kode_konfirmasi"
            type="text"
            class="form-control"
            id="kode-konfirmasi"
            placeholder="Masukan Kode Konfirmasi"
          />
        </div>

        <hr />

        <div class="form-group pt-3 text-center">
          <button
            :disabled="loading"
            type="submit"
            class="btn btn-primary px-4"
          >
            Konfirmasi
          </button>
        </div>
      </form>

      <form @submit.prevent="setNewPassword" v-if="mode === 'newpassword'">
        <div class="form-group pt-3">
          <label for="new-password">Password Baru</label>
          <input
            :disabled="loading"
            v-model="password"
            type="password"
            class="form-control"
            id="new-password"
            placeholder="Masukan Password Baru"
          />
        </div>

        <div class="form-group pb-3">
          <label for="confirm-new-password">Konfirmasi Password Baru</label>
          <input
            :disabled="loading"
            v-model="password_confirmation"
            type="password"
            class="form-control"
            id="confirm-new-password"
            placeholder="Masukan Kembali Password Baru"
          />
        </div>

        <hr />

        <div class="form-group pt-3 text-center">
          <button
            :disabled="loading"
            type="submit"
            class="btn btn-primary px-4"
          >
            Simpan
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import { load } from "recaptcha-v3";
export default {
  data() {
    return {
      no_registrasi: "",
      kode_konfirmasi: "",

      password: "",
      password_confirmation: "",
      timeout: "",

      recaptcha_token: "",

      loading: false,

      mode: "",
    };
  },
  methods: {
    request() {
      let request = () => {
        this.loading = true;
        this.$axios
          .post(
            "/pendaftar/forgotpassword",
            {
              no_registrasi: this.no_registrasi,
            },
            {
              headers: { "x-recaptcha-token": this.recaptcha_token },
            }
          )
          .then((res) => {
            this.loading = false;
            this.$root.$emit("setNotif", {
              title: "Sukses!",
              message: res.data.description,
              type: "success",
            });
            this.mode = "konfirmasi";
            this.generateToken();

            if (!res.data.expired) return;
            this.timeout_interval = setInterval(() => {
              let now = new Date().getTime();
              let expired = new Date(res.data.expired).getTime();

              // sisa waktu (microtime) di konversi ke detik
              let second = Math.floor((expired - now) / 1000);

              let date = {
                min: ("0" + Math.floor(second / 60)).slice(-2), // menit = detik / 60 (desimal)
                sec: ("0" + (second % 60)).slice(-2), // detik = sisa bagi 60 (n % 60)
              };

              if (second < 0) {
                // waktu habis
                this.$root.$emit("setNotif", {
                  message: "Waktu konfirmasi Habis",
                  type: "info",
                });
                this.mode = "";
                this.generateToken();
                clearInterval(this.timeout_interval);
              }

              this.timeout = `${date.min}:${date.sec}`;
            }, 1000);
          })
          .catch(() => {
            this.loading = false;
          });
      };
      this.$validator.validateAll().then((result) => {
        if (result) request();
      });
    },
    konfirmasi() {
      this.loading = true;
      this.$axios
        .post(
          "/pendaftar/forgotpassword/checktoken",
          {
            "x-forgotpassword-token": this.kode_konfirmasi,
          },
          {
            headers: { "x-recaptcha-token": this.recaptcha_token },
          }
        )
        .then(() => {
          clearInterval(this.timeout_interval);
          this.mode = "newpassword";
          this.loading = false;
          this.generateToken();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setNewPassword() {
      this.loading = true;
      this.$axios
        .post(
          "/pendaftar/resetpassword",
          {
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
          {
            headers: {
              "x-recaptcha-token": this.recaptcha_token,
              "x-forgotpassword-token": this.kode_konfirmasi,
            },
          }
        )
        .then((res) => {
          this.$root.$emit("setNotif", {
            title: "Sukses!",
            message: res.data.deskripsi,
            type: "success",
          });
          this.$router.push("/");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async generateToken() {
      this.$recaptcha = {};
      load(this.$psb.config.recaptchaKey, {
        useRecaptchaNet: true,
      })
        .then((recaptcha) => {
          let gb = document.querySelector(".grecaptcha-badge");
          if (gb) gb.setAttribute("class", "grecaptcha-badge");

          recaptcha
            .execute()
            .then((token) => {
              this.recaptcha_token = token;
            })
            .catch(() => {});
          this.$recaptcha = recaptcha;
        })
        .catch(() => {});
    },
  },
  beforeDestroy() {
    let gb = document.querySelector(".grecaptcha-badge");
    if (gb) gb.setAttribute("class", "grecaptcha-badge d-none");
  },
  created() {
    this.timeout_interval = {};
  },
  mounted() {
    this.generateToken();
  },
};
</script>

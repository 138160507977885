import Nes from "@hapi/nes/lib/client";
import config from "../config";
import store from "../store";

const socket = new Nes.Client(config.wsHost, {
  ws: { maxPayload: 1000 },
});

const doConnect = () => {
  socket.onError = (err) => {};
  socket
    .connect({
      auth: {
        headers: {
          "user-agent": navigator.userAgent,
          "x-ppdb-token": store.state.token,
        },
      },
    })
    .catch((e) => {});
};

const doDisconnect = () => {
  socket.disconnect();
};

socket.instance = {
  connect: doConnect,
  disconnect: doDisconnect,
};

export default socket;

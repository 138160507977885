import Store from "../store";

var scopeHandler = (scope, group) => {
  var result = [];
  if (Array.isArray(scope)) {
    for (var i in scope) {
      for (var j in group) {
        if (scope[i].startsWith(group[j])) {
          result.push({
            group: group[j],
            scope: scope[i],
          });
        }
      }
    }
  } else {
    for (var j in group) {
      if (scope.startsWith(group[j])) {
        result.push({
          group: group[j],
          scope: scope,
        });
      }
    }
  }
  if (result.length > 0) {
    var n = result[0];
    var data = n.scope.substr(n.group.length + 1);
    return [n.group ? n.group : null, data ? data : null];
  } else {
    return [null, null];
  }
};

var scope = {
  user() {
    var group = ["user"];
    var token = Store.state.token;
    if (token) {
      var payload = JSON.parse(atob(token.split(".")[0]));
      var scope = payload.scope;
      var result = scopeHandler(scope, group);
      return {
        nama_lengkap: payload.nama_lengkap,
        type: result[0],
        uuid: result[1] || payload.uuid_person,
        gender: payload.gender,
      };
    } else {
      return {
        nama_lengkap: null,
        uuid: null,
        gender: null,
      };
    }
  },
  level() {
    var group = ["pendaftar"];
    var token = Store.state.token;
    if (token) {
      var scope = JSON.parse(atob(token.split(".")[0])).scope;
      var result = scopeHandler(scope, group);
      return {
        type: result[0],
        param: result[1],
      };
    } else {
      return {
        type: null,
        param: null,
      };
    }
  },
  scopeType() {
    var token = Store.state.token;
    if (token) {
      var scope = JSON.parse(atob(token.split(".")[0])).scope;
      if (Array.isArray(scope)) {
        return "array";
      } else {
        return typeof scope;
      }
    }
    return false;
  },
  config() {
    var token = Store.state.token;
    if (token) {
      return JSON.parse(atob(token.split(".")[0])).config;
    } else {
      return {};
    }
  },
};

export default scope;

import fileAttachment from "./fileAttachment";

const notfound = "";
export default (el, binding, vnode) => {
  if (binding.oldValue !== binding.value) {
    const props = [];
    props["style"] = el.getAttribute("style") || "";
    el.setAttribute("loading", true);
    setTimeout(() => {
      if (!binding.modifiers.bg) el.setAttribute("style", `${props["style"]}`);
    }, 0);
    if (binding.value) {
      fileAttachment(binding.value, !binding.modifiers.nocache)
        .then((data) => {
          el.removeAttribute("loading");
          if (binding.modifiers.bg) {
            el.setAttribute(
              "style",
              `background-image: url(${data});${props["style"]}`
            );
          } else {
            el.setAttribute("style", props["style"]);
            el.setAttribute("src", data);
          }
        })
        .catch(() => {
          el.removeAttribute("loading");
          if (binding.modifiers.bg)
            el.setAttribute(
              "style",
              `background-image: url(${notfound});${props["style"]}`
            );
          else {
            el.setAttribute("style", props["style"]);
            el.setAttribute("src", notfound);
          }
        });
    }
  }
};

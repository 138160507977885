import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
/*
import {
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
*/

import {
  faAddressCard,
  faUsersCog,
  faFileAlt,
  faMoneyCheckAlt,
  faPrint,
  faCheckCircle,
  faUserCircle,
  faFileUpload,
  faUpload,
  faGraduationCap,
  faUser,
  faSave,
  faSpinner,
  faKey,
  faDownload,
  faFirstAid,
  faBookReader,
  faTshirt,
  faHandshake,
  faPersonBooth,
  faUsers,
  faUserShield,
  faLayerGroup,
  faServer,
  faSearch,
  faEye,
  faEyeSlash,
  faArrowRight,
  faPlus,
  faSignOutAlt,
  faTrash,
  faEdit,
  faTimes,
  faFileMedicalAlt,
  faPhone,
  // faFilter,
  faFileExport,
  faPen,
  faChartBar,
  faCaretRight,
  faCaretDown,
  faBug,
  faSync,
  faExclamationCircle,
  faImage,
  faCheck,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookSquare,
  faYoutubeSquare,
  faTwitterSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faExclamationCircle,
  faInstagramSquare,
  faPen,
  faCheck,
  faImage,
  faYoutubeSquare,
  faFacebookSquare,
  faTwitterSquare,
  faAddressCard,
  faUsersCog,
  faFileAlt,
  faMoneyCheckAlt,
  faPrint,
  faUserCircle,
  faFileUpload,
  faUpload,
  faCheckCircle,
  faGraduationCap,
  faUser,
  faSave,
  faSpinner,
  faKey,
  faDownload,
  faFirstAid,
  faBookReader,
  faTshirt,
  faHandshake,
  faPersonBooth,
  faUsers,
  faUserShield,
  faLayerGroup,
  faServer,
  faSearch,
  faEye,
  faEyeSlash,
  faArrowRight,
  faPlus,
  faSignOutAlt,
  faTrash,
  faEdit,
  faTimes,
  faFileMedicalAlt,
  faPhone,
  // faFilter,
  faFileExport,
  faChartBar,
  faCaretRight,
  faCaretDown,
  faBug,
  faSync,
  faMoneyBillAlt
);

Vue.component("fa-icon", FontAwesomeIcon);

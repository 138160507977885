// noinspection JSValidateTypes

import Vue from "vue";
import axios from "./plugins/axios-vue";
import Plugin from "./plugins";
import "./plugins"; /* perlu diletakkan paling atas */
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TopProgressBar from "@/components/TopProgressBar.vue";

import { Settings } from "luxon";
Settings.defaultLocale = "ID";
Settings.defaultZone = "Asia/Jakarta";

Vue.config.productionTip = false;

/* GLOBAL PROGRESS-BAR ELEMENT */
const bar = (Vue.prototype.$topprogressbar = new Vue(TopProgressBar).$mount());
document.body.appendChild(bar.$el);

Vue.use(Plugin);

new Vue({
  axios,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import axios from "../axios-vue";

export default {
  getAllWilayah(callback, id) {
    axios
      .get("/setting/wilayah" + (id ? "/" + id : ""))
      .then((res) => {
        callback(res.data);
      })
      .catch(() => {});
  },
  getAllBlok(callback, id) {
    axios
      .get("/setting/blok" + (id ? "/" + id : ""))
      .then((res) => {
        callback(res.data);
      })
      .catch(() => {});
  },
  getAllKamar(callback, id) {
    axios
      .get("/setting/kamar" + (id ? "/" + id : ""))
      .then((res) => {
        callback(res.data);
      })
      .catch(() => {});
  },
};

import axios from "../axios-vue";
import Domisili from "./Domisili";

export default {
  handleNegara(id_negara, callback) {
    const biodata = {
      provinsi: null,
      kabupaten: null,
      kecamatan: null,
      list_provinsi: [],
    };
    if (id_negara) {
      axios
        .get("/setting/negara/" + id_negara)
        .then((res) => {
          biodata.list_provinsi = res.data.list_provinsi;
          if (res.data.list_provinsi.length < 1) {
            biodata.provinsi = null;
            biodata.kabupaten = null;
            biodata.kecamatan = null;
          }
          callback(biodata);
        })
        .catch(() => {});
    } else {
      callback(biodata);
    }
  },
  handleProvinsi(id_provinsi, callback) {
    const biodata = {
      kabupaten: null,
      kecamatan: null,
      list_kabupaten: [],
    };
    if (id_provinsi) {
      axios
        .get("/setting/provinsi/" + id_provinsi)
        .then((res) => {
          biodata.list_kabupaten = res.data.list_kabupaten;
          if (res.data.list_kabupaten.length < 1) {
            biodata.kabupaten = null;
            biodata.kecamatan = null;
          }
          callback(biodata);
        })
        .catch(() => {});
    } else {
      callback(biodata);
    }
  },
  handleKabupaten(id_kabupaten, callback) {
    const biodata = {
      kecamatan: null,
      list_kecamatan: [],
    };
    if (id_kabupaten) {
      axios
        .get("/setting/kabupaten/" + id_kabupaten)
        .then((res) => {
          biodata.list_kecamatan = res.data.list_kecamatan;
          if (res.data.list_kecamatan.length < 1) {
            biodata.kecamatan = null;
          }
          callback(biodata);
        })
        .catch(() => {});
    } else {
      callback(biodata);
    }
  },

  handleWilayah(id_wilayah, callback) {
    let domisili;
    if (id_wilayah) {
      domisili = {
        id_blok: null,
        id_kamar: null,
        wilayah: null,
        blok: null,
        kamar: null,
        list_blok: [],
      };
      Domisili.getAllWilayah((data) => {
        domisili.id_blok = null;
        domisili.id_kamar = null;
        domisili.wilayah = data.nama;
        domisili.blok = null;
        domisili.kamar = null;
        domisili.list_blok = data.list_blok;
        callback(domisili);
      }, id_wilayah);
    } else {
      domisili = {
        id_blok: null,
        id_kamar: null,
        wilayah: null,
        blok: null,
        kamar: null,
        list_blok: [],
      };
      callback(domisili);
    }
  },
  handleBlok(id_blok, callback) {
    let domisili;
    if (id_blok) {
      domisili = {
        id_kamar: null,
        blok: null,
        kamar: null,
        list_kamar: [],
      };
      Domisili.getAllBlok((data) => {
        domisili.id_kamar = null;
        domisili.blok = data.nama;
        domisili.kamar = null;
        domisili.list_kamar = data.list_kamar;
        callback(domisili);
      }, id_blok);
    } else {
      domisili = {
        id_kamar: null,
        blok: null,
        kamar: null,
        list_kamar: [],
      };
      callback(domisili);
    }
  },
  handleKamar(id_kamar, callback) {
    const domisili = {
      kamar: null,
    };
    if (id_kamar) {
      Domisili.getAllKamar((data) => {
        domisili.kamar = data.nama;
        callback(domisili);
      }, id_kamar);
    }
  },

  handleLembaga(id_lembaga, callback) {
    const data = {
      jurusan: [],
      id_jurusan: null,
    };
    if (id_lembaga) {
      axios
        .get("/setting/lembaga/jurusan?lembaga=" + id_lembaga)
        .then((res) => {
          data.jurusan = res.data;
          callback(data);
        })
        .catch(() => {});
    } else {
      callback(data);
    }
  },
};

import axios from "../axios-vue";

export default async ({ id_kecamatan, id_kabupaten }) => {
  try {
    const main = {};
    if (!id_kecamatan) {
      main.kec = { data: { id: null, id_kabupaten: id_kabupaten } };
    } else {
      main.kec = await axios.get("/setting/kecamatan/" + id_kecamatan);
    }
    main.kab = await axios.get(
      "/setting/kabupaten/" + main.kec.data.id_kabupaten
    );
    main.prov = await axios.get(
      "/setting/provinsi/" + main.kab.data.id_provinsi
    );
    main.negara = await axios.get(
      "/setting/negara/" + main.prov.data.id_negara
    );
    return {
      kec: main.kec.data,
      kab: main.kab.data,
      prov: main.prov.data,
      negara: main.negara.data,
    };
  } catch (e) {
    throw Error(e);
    // return Promise.reject(e);
  }
};

<template>
  <div @dblclick="close">
    <div
      class="modal"
      v-if="show"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="display: block; z-index: 1056"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="spinner">
          <fa-icon :icon="['fas', 'spinner']" pulse />
        </div>
      </div>
    </div>
    <div
      class="modal-backdrop fade"
      style="z-index: 1055"
      :class="{ show: show }"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    close() {
      document.body.classList.remove("modal-open");
      setTimeout(() => {
        this.show = false;
      }, 100);
      this.$store.commit("setLoading", false);
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 300);
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
    setTimeout(() => {
      this.show = false;
    }, 100);
    this.$store.commit("setLoading", false);
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  // border: 8px solid #aaa;
  // border-left-color: #fff;
  // border-radius: 50%;
  margin: 0 auto;
}

.spinner svg {
  width: 150px !important;
  height: 150px !important;
  color: #fff;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Axios from "../plugins/axios-vue";
import Store from "../store";

import BlankLayout from "@/layouts/BlankLayout";
import DashLayout from "@/layouts/DashLayout";

import ForgotPassword from "../views/ForgotPassword";

import VueRouteMiddleware from "vue-route-middleware";

import AuthMiddleware from "./middlewares/auth";
import GuestMiddleware from "./middlewares/guest";

import Nes from "../plugins/nesClient";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BlankLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Landing"),
        meta: {
          middleware: ["GuestMiddleware"],
        },
      },

      {
        path: "login",
        name: "Login",
        component: () => import("@/views/Login"),
        meta: {
          middleware: ["GuestMiddleware"],
        },
      },
      {
        path: "register",
        name: "Formulir PSBNJ",
        component: () => import("@/views/Register"),
        meta: {
          middleware: ["GuestMiddleware"],
        },
      },
      {
        path: "forgot-password",
        name: "Ganti Password",
        component: ForgotPassword,
        meta: {
          middleware: ["GuestMiddleware"],
        },
      },
    ],
  },
  {
    path: "/",
    component: DashLayout,
    children: [
      {
        path: "",
        name: "Profile",
        component: () => import("@/views/Pendaftar/Profile"),
        meta: {
          middleware: ["AuthMiddleware"],
        },
      },
      {
        path: "akun",
        name: "Akun",
        component: () => import("@/views/Pendaftar/Akun"),
        meta: {
          middleware: ["AuthMiddleware"],
        },
      },
      {
        path: "ortuwali",
        name: "Orang Tua & Wali",
        component: () => import("@/views/Pendaftar/Keluarga"),
        meta: {
          middleware: ["AuthMiddleware"],
        },
      },
      {
        path: "berkas",
        name: "Upload Berkas",
        component: () => import("@/views/Pendaftar/Berkas"),
        meta: {
          middleware: ["AuthMiddleware"],
        },
      },
      {
        path: "pembayaran",
        name: "Pembayaran",
        component: () => import("@/views/Pendaftar/Pembayaran"),
        meta: {
          middleware: ["AuthMiddleware"],
        },
      },
      {
        path: "cetak",
        name: "Cetak Pendaftaran",
        component: () => import("@/views/Pendaftar/Cetak"),
        meta: {
          middleware: ["AuthMiddleware"],
        },
      },
      {
        path: "/angket",
        component: () => import("@/views/Pendaftar/Angket/Index.vue"),
        meta: {
          middleware: ["AuthMiddleware"],
          name: "Angket",
        },
        children: [
          {
            path: "",
            name: "List Angket",
            component: () => import("@/views/Pendaftar/Angket/Angket.vue"),
            props: true,
            meta: {
              middleware: ["AuthMiddleware"],
            },
          },
          {
            path: "formangket/:id_form?",
            name: "Form Angket",
            component: () => import("@/views/Pendaftar/Angket/FormAngket.vue"),
            props: true,
            meta: {
              middleware: ["AuthMiddleware"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      Axios.get("/pendaftar/logout", {
        disableExptimestamp: true,
        disableErrNotif: true,
      }).catch(() => {});
      Store.commit("setToken", "");
      Store.commit("setExptimestamp", "0");
      Nes.instance.disconnect();
      next("/");
    },
  },
  {
    path: "*",
    name: "whatever",
    redirect: "/",
    meta: {
      layout: "full-layout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(VueRouteMiddleware({ AuthMiddleware, GuestMiddleware }));

export default router;

import "./fontAwesome";
import "./axios-vue";
import secureImg from "./secureImg";

import ApiSelect from "./api/HandlerSelect";
import ApigetFullAlamat from "./api/getFullAlamat";

import VeeValidate, { Validator } from "vee-validate";
import id from "vee-validate/dist/locale/id";

import TokenParser from "./TokenParser";
import { dateFormat } from "./date/dateFormat";

import nes from "./nesClient";

import Vue from "vue";

import config from "../config";

export default {
  install(Vue, options) {
    Vue.use(VeeValidate);
    Validator.localize("id", id);
    Vue.directive("auth-image", {
      bind: secureImg,
      update: secureImg,
    });
    Vue.prototype.$psb = {
      config,
      TokenParser,
      nes,
      api: {
        Select: ApiSelect,
        getFullAlamat: ApigetFullAlamat,
      },
    };
    // Vue.mixin({
    //   mounted() {
    //     console.log("assd")
    //   },
    // })
    Vue.mixin({
      filters: {
        date: dateFormat,
        fillDef(str) {
          return str ? str : "-";
        },
        enumV(val) {
          return {
            to(jenis) {
              switch (jenis) {
                case "LP":
                  switch (val) {
                    case "P":
                      return "Perempuan";
                    case "L":
                      return "Laki-laki";
                    default:
                      return "-";
                  }
                case "YT":
                  switch (val) {
                    case "Y":
                      return "Ya";
                    case "T":
                      return "Tidak";
                    default:
                      return "-";
                  }
                default:
                  throw Error(`opsi enum '${jenis}' tidak terdefinisi`);
              }
            },
          };
        },
      },
    });
  },
};

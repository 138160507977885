<template>
  <div>
    <MobileNav :biodatas="biodatas" @refreshBio="getBiodata" />
    <div class="container-fluid">
      <div class="row">
        <NavLeft :biodatas="biodatas" @refreshBio="getBiodata" />
        <main
          :key="$route.path"
          role="main"
          class="col-md-9 ml-sm-auto col-lg-10 px-md-4 mb-4"
        >
          <router-view :biodatas="biodatas" @refreshBio="getBiodata" />
          <footer>
            Copyright &copy; 2019-{{ year }} Nurul Jadid - All Rights Reserved
          </footer>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MobileNav: () => import("@/components/MobileNav"),
    NavLeft: () => import("@/components/NavLeft"),
  },

  data() {
    return {
      biodatas: {},
    };
  },
  methods: {
    getBiodata() {
      if (!this.$store.state.token) return;
      this.$store.commit("setLoading", true);
      this.$topprogressbar.start();
      this.$axios
        .get("/pendaftar/" + this.$psb.TokenParser.level().param)
        .then((res) => {
          this.$store.commit("setLoading", false);
          this.biodatas = res.data;
          setTimeout(() => this.$topprogressbar.finish(), 500);
        })
        .catch(() => {
          this.$topprogressbar.fail();
          this.$store.commit("setLoading", false);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },

  watch: {
    $route() {
      this.getBiodata();
    },
  },

  computed: {
    year() {
      return new Date().getFullYear();
    },
  },

  mounted() {
    this.getBiodata();
  },
};
</script>

<style lang="scss">
footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 2px solid rgba(129, 142, 148, 0.07);
  color: rgba(129, 142, 148, 0.5);
  font-weight: 600;
  font-size: 13px;
}
</style>

import axios from "./axios-vue";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";

localforage.config({
  // driver      : localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  name: "ppdbnj",
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: "secure-images", // Should be alphanumeric, with underscores.
  description: "",
});

const storages = {
  getItem(url) {
    return new Promise((resolve, reject) => {
      localforage.getItem(url, (err, value) => {
        if (err) reject("Error ketika `getItem` di localforage, " + err);
        resolve(value);
      });
    });
  },
  setItem(url, data) {
    return new Promise((resolve, reject) => {
      localforage.setItem(url, data, (err) => {
        if (err) reject("Error ketika `setItem` di localforage, " + err);
        resolve(data);
      });
    });
  },
};

const request = async (url) => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default async (url, useCache = true) => {
  try {
    let data;
    if (useCache) {
      data = await storages.getItem(url);
      if (!data) {
        data = await request(url);
        const raw = Object.assign(data, {});
        try {
          await storages.setItem(url, raw);
        } catch (e) {
          // throw Error(e);
          Error(e);
        }
      }
    } else {
      data = await request(url);
    }

    const urlCreator = window.URL || window.webkitURL;
    return Promise.resolve(urlCreator.createObjectURL(data));
  } catch (e) {
    return Promise.reject(e);
  }
};
